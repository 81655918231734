// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@media(min-width:667.98px){.cqd-HsrP{display:flex;flex-wrap:wrap}}.CxRCwB4f{flex-basis:50%;padding:0 0 40px}@media(min-width:667.98px){.CxRCwB4f{padding:0}}@media(min-width:947.98px){.CxRCwB4f{padding:0 0 40px}}.CVerFEqx{display:none}@media(min-width:667.98px){.CVerFEqx{display:block;flex-basis:100%;padding:0}}._56pBEpcI{height:auto}@media(min-width:667.98px){._56pBEpcI{max-width:544px;width:80%}}.tM-0TK-0{font-size:max(32px,min(4vw,50px));font-weight:400;letter-spacing:-.02em;line-height:1.1;line-height:99.28%;margin:0;padding:0 0 32px;transition:font-size .15s}@media(min-width:667.98px){.tM-0TK-0{max-width:80%;padding:0 0 70px}.VV43mLTc{display:none}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"wrapper": "cqd-HsrP",
	"cell": "CxRCwB4f",
	"cellDate": "CVerFEqx",
	"typo": "_56pBEpcI",
	"title": "tM-0TK-0",
	"titleDate": "VV43mLTc"
};
module.exports = ___CSS_LOADER_EXPORT___;
